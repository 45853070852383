import React, { useState, useEffect } from 'react';
import GradientSlider from '../../Reusables/gradient_slider';

export default function MortgageCalc({ property }) {
    const [homePrice, setHomePrice] = useState(property?.price || 539000);
    const [downPayment, setDownPayment] = useState((property?.Price || 539000) * 0.2);
    const [downPaymentPercent, setDownPaymentPercent] = useState(20);
    const [loanTerm, setLoanTerm] = useState(30);
    const [interestRate, setInterestRate] = useState(6.85);
    const [propertyTax, setPropertyTax] = useState(440);
    const [homeInsurance, setHomeInsurance] = useState(171);
    const [isDownPaymentExpanded, setIsDownPaymentExpanded] = useState(false);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [principalAndInterest, setPrincipalAndInterest] = useState(0);

    useEffect(() => {
        calculateMortgage();
    }, [homePrice, downPayment, loanTerm, interestRate, propertyTax, homeInsurance]);

    const calculateMortgage = () => {
        const principal = homePrice - downPayment;
        const monthlyRate = interestRate / 100 / 12;
        const numberOfPayments = loanTerm * 12;

        const monthlyPrincipalAndInterest =
            (principal * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) /
            (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

        setPrincipalAndInterest(monthlyPrincipalAndInterest);
        setMonthlyPayment(monthlyPrincipalAndInterest + propertyTax + homeInsurance);
    };

    const handleHomePriceChange = (value) => {
        setHomePrice(value);
        setDownPayment((value * downPaymentPercent) / 100);
    };

    const handleDownPaymentChange = (value) => {
        setDownPayment(value);
        setDownPaymentPercent((value / homePrice) * 100);
    };

    const handleDownPaymentPercentChange = (value) => {
        setDownPaymentPercent(value);
        setDownPayment((homePrice * value) / 100);
    };

    return (
        <div className="w-full max-w-3xl mx-auto p-6 rounded-lg shadow-lg bg-white text-gray-800">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-4xl font-bold bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text">
                    ${Number(monthlyPayment.toFixed(0)).toLocaleString()} per month
                </h2>
                <button
                    onClick={() => window.location.reload()}
                    className="px-4 py-2 border border-[#E80458] text-[#E80458] rounded-lg hover:bg-[#E80458] hover:text-white transition-colors"
                >
                    Reset
                </button>
            </div>

            <div className="w-full h-4 bg-gray-200 rounded-full mb-4 flex">
                <div
                    style={{ width: `${(principalAndInterest / monthlyPayment) * 100}%` }}
                    className="bg-[#EC6227] rounded-l-full"
                ></div>
                <div
                    style={{ width: `${(propertyTax / monthlyPayment) * 100}%` }}
                    className="bg-[#E80458]"
                ></div>
                <div
                    style={{ width: `${(homeInsurance / monthlyPayment) * 100}%` }}
                    className="bg-[#5900B3] rounded-r-full"
                ></div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                    <p className="font-semibold">Principal and interest</p>
                    <p className="text-[#EC6227]">${Number(principalAndInterest.toFixed(0)).toLocaleString()}</p>
                </div>
                <div>
                    <p className="font-semibold">Property taxes</p>
                    <p className="text-[#E80458]">${propertyTax}</p>
                </div>
                <div>
                    <p className="font-semibold">Homeowners insurance</p>
                    <p className="text-[#5900B3]">${homeInsurance}</p>
                </div>
            </div>

            <div className="mb-4 border border-[#E80458] rounded-lg p-4">
                <div className="flex justify-between items-center mb-2">
                    <p className="font-semibold">Down payment</p>
                    <p className="font-bold text-[#E80458]">{downPaymentPercent.toFixed(0)}% (${downPayment.toFixed(0)})</p>
                </div>
                <p className="text-sm mb-2">Home price: ${homePrice.toFixed(0)}</p>
                <p className="text-sm">Loan details: {loanTerm}-yr fixed, {interestRate}%</p>
                <button
                    onClick={() => setIsDownPaymentExpanded(!isDownPaymentExpanded)}
                    className="w-full mt-2 flex items-center justify-center text-[#E80458] hover:bg-[#E80458] hover:text-white transition-colors"
                >
                    {isDownPaymentExpanded ? 'Hide details' : 'Show details'}
                </button>
            </div>

            {isDownPaymentExpanded && (
                <div className="mb-4 border border-[#E80458] rounded-lg p-4">
                    <label htmlFor="downPayment" className="mb-2 block font-semibold">Down payment</label>
                    <div className="flex gap-2 mb-2">
                        <input
                            id="downPayment"
                            type="number"
                            value={downPayment}
                            onChange={(e) => handleDownPaymentChange(Number(e.target.value))}
                            className="w-1/2 p-2 border rounded-lg"
                        />
                        <input
                            type="number"
                            value={downPaymentPercent}
                            onChange={(e) => handleDownPaymentPercentChange(Number(e.target.value))}
                            className="w-1/2 p-2 border rounded-lg"
                        />
                    </div>
                    <input
                        type="range"
                        value={downPaymentPercent}
                        onChange={(e) => handleDownPaymentPercentChange(Number(e.target.value))}
                        max={100}
                        step={1}
                        className="w-full mt-4"
                    />

                    <button className="w-full mt-4 px-4 py-2 border border-[#E80458] text-[#E80458] rounded-lg hover:bg-[#E80458] hover:text-white transition-colors">
                        Add my home equity
                    </button>
                    <div className="mt-4">
                        <div className="flex justify-between">
                            <p>Cash</p>
                            <p>${downPayment.toFixed(0)}</p>
                        </div>
                        <div className="flex justify-between">
                            <p>Home Equity</p>
                            <p>$0</p>
                        </div>
                        <div className="flex justify-between font-bold mt-2">
                            <p>Total Down:</p>
                            <p className="text-[#E80458]">${downPayment.toFixed(0)} ({downPaymentPercent.toFixed(0)}%)</p>
                        </div>
                    </div>
                </div>
            )}

            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                    <label htmlFor="homePrice" className="mb-2 block font-semibold">Home price: ${homePrice.toLocaleString()}</label>
                    <GradientSlider
                        value={homePrice} // Directly pass the homePrice value
                        onChange={handleHomePriceChange} // Pass the updated handler
                        max={10000000}
                        step={1000}
                        className="w-full mb-4"
                    />
                </div>

                <div>
                    <label htmlFor="loanTerm" className="mb-2 block font-semibold">Loan term (years)</label>
                    <input
                        id="loanTerm"
                        type="number"
                        value={loanTerm}
                        onChange={(e) => setLoanTerm(Number(e.target.value))}
                        className="w-full p-2 border rounded-lg"
                    />
                </div>
                <div>
                    <label htmlFor="interestRate" className="mb-2 block font-semibold">Interest rate (%)</label>
                    <input
                        id="interestRate"
                        type="number"
                        step="0.01"
                        value={interestRate}
                        onChange={(e) => setInterestRate(Number(e.target.value))}
                        className="w-full p-2 border rounded-lg"
                    />
                </div>
                <div>
                    <label htmlFor="propertyTax" className="mb-2 block font-semibold">Property tax (monthly)</label>
                    <input
                        id="propertyTax"
                        type="number"
                        value={propertyTax}
                        onChange={(e) => setPropertyTax(Number(e.target.value))}
                        className="w-full p-2 border rounded-lg"
                    />
                </div>
                <div>
                    <label htmlFor="homeInsurance" className="mb-2 block font-semibold">Homeowners insurance (monthly)</label>
                    <input
                        id="homeInsurance"
                        type="number"
                        value={homeInsurance}
                        onChange={(e) => setHomeInsurance(Number(e.target.value))}
                        className="w-full p-2 border rounded-lg"
                    />
                </div>
            </div>
        </div>
    );
}
