import React, { useState, useEffect, useRef, useContext } from 'react';
import { Map, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ListingCard from './map_components/listingaside';
import PocketBaseContext from '../../PocketBaseContext';
import { useNavigate } from 'react-router-dom';
import FilterBar from './map_components/filter_bar';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';


const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN_API;

const MapComponent = () => {
    const [mapStyle, setMapStyle] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [isDetailsVisible, setIsDetailsVisible] = useState(false);
    const propertyRefs = useRef({});
    const [errorText, setErrorText] = useState("");
    const pb = useContext(PocketBaseContext);
    const [homes, setHomes] = useState([]);
    const [filteredHomes, setFilteredHomes] = useState([]); // Filtered homes to display
    const [hoveredProperty, setHoveredProperty] = useState(null);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [handleMarkerClick, setHandleMarkerClick] = useState(null);
    const [PropertyStatus, setPropertyStatus] = useState('ForSale');

    const mapRef = useRef(null); // Ref for the Map component
    const [userLocation, setUserLocation] = useState(null);
    const [userLatitude, setUserLatitude] = useState(null);
    const [userLongitude, setUserLongitude] = useState(null);
    const [userCity, setUserCity] = useState('');

    // Filter states
    const [priceRange, setPriceRange] = useState([0, 10000000]);
    const [bedroomsRange, setBedroomsRange] = useState([1, 10]);
    const [bathrooms, setBathrooms] = useState([1, 10]);
    const [additionalFilters, setAdditionalFilters] = useState({
        parking: false,
        pool: false,
        garden: false,
    });


    useEffect(() => {
        const getUserLocation = async () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLatitude(latitude);
                    setUserLongitude(longitude);

                    console.log("User Location:", userLocation, latitude, longitude);

                    // Convert coordinates to a place name
                    const placeName = await getPlaceFromCoordinates(latitude, longitude);
                    console.log('Place name:', placeName);
                    setUserCity(placeName);
                    // Fetch Zillow data after setting the place name
                    fetchZillowData(placeName);
                }, (error) => {
                    console.error('Geolocation error:', error);
                    // Fallback to a default location if geolocation fails
                    const bostonCoordinates = { latitude: 42.3601, longitude: -71.0589 };
                    setUserCity(bostonCoordinates);
                    // Fetch Zillow data for the default location
                    fetchZillowData(bostonCoordinates);
                });
            } else {
                console.error("Geolocation is not supported by this browser.");
            }
        };

        getUserLocation();
    }, []);

    const getPlaceFromCoordinates = async (latitude, longitude) => {
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`;

        try {
            const response = await axios.get(url);
            const features = response.data.features;

            if (features.length > 0) {
                const place = features[0];

                // Extract city and state
                const city = place.context.find(c => c.id.includes('place')).text.toUpperCase(); // Get the city
                const state = place.context.find(c => c.id.includes('region')).text.slice(0, 2); // Get the state

                setUserLocation(`${city}, ${state}`);
                return `${city}, ${state}`; // Format as "City, State"
            }
            return "Location not found";
        } catch (error) {
            console.error('Error fetching place name:', error);
            return "Error fetching location";
        }
    };

    const fetchZillowData = async (location) => {
        const options = {
            method: 'GET',
            url: 'https://zillow-com1.p.rapidapi.com/propertyExtendedSearch',
            params: {
                location: `${location}`,
                status_type: `${PropertyStatus}`,
                home_type: 'Houses',
            },
            headers: {
                'x-rapidapi-key': 'f8ccc0b787mshafd5b0cf38ad46ep1f27f1jsn9699ae0d879e',
                'x-rapidapi-host': 'zillow-com1.p.rapidapi.com'
            }
        };

        try {
            const response = await axios.request(options);
            // console.log("API Response: ", response.data.props);  // Log the array of homes

            // Get the homes array and limit to 100
            const zillowHomes = response.data.props.slice(0, 100);
            setHomes(zillowHomes);  // Store homes in state
        } catch (error) {
            console.error('Error fetching Zillow homes:', error);
            setErrorText("An error occurred while fetching Zillow data.");
        }
    };

    useEffect(() => {
        fetchZillowData(userCity); // Fetch Zillow data on PropertyStatus change
    }, [PropertyStatus, userCity]);

    const applyFilters = () => {
        console.log('homes', homes)
        const filtered = homes.filter((home) => {
            const homePrice = Number(home.price)
            const [minPrice, maxPrice] = priceRange;
            const matchesPrice = homePrice >= minPrice && homePrice <= maxPrice;
            const homeBedrooms = Number(home.bedrooms);
            const [minBedrooms, maxBedrooms] = bedroomsRange;
            const matchesBedrooms = homeBedrooms >= minBedrooms && homeBedrooms <= maxBedrooms;
            const homeBathrooms = Number(home.bathrooms);
            const [minBathrooms, maxBathrooms] = bathrooms;
            const matchesBathrooms = homeBathrooms >= minBathrooms && homeBathrooms <= maxBathrooms;
            // const matchesAddress = home.Address.includes(searchQuery) || searchQuery.includes(home.Address);
            return matchesPrice && matchesBedrooms && matchesBathrooms;
        });

        setFilteredHomes(filtered);
        console.log('filter', filteredHomes)
    };

    useEffect(() => {
        applyFilters();
    }, [homes, priceRange, bedroomsRange, bathrooms]);

    useEffect(() => {
        // Handle resize issue by forcing map to adjust its dimensions
        const handleResize = () => {
            if (mapRef.current) {
                mapRef.current.resize();
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial resize to fix map issue

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="flex flex-col h-full p-4 min-h-screen">
            <FilterBar
                priceRange={priceRange}
                setPriceRange={setPriceRange}
                bedroomsRange={bedroomsRange}
                setBedroomsRange={setBedroomsRange}
                bathrooms={bathrooms}
                setBathrooms={setBathrooms}
                additionalFilters={additionalFilters}
                setAdditionalFilters={setAdditionalFilters}
                onFilterChange={applyFilters}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                propertyStatus={PropertyStatus}
                setPropertyStatus={setPropertyStatus} // Added propertyType to FilterBar props
            />
            <div className='flex flex-row h-[100vh]'>
                <div className="w-full rounded-lg overflow-hidden p-1">
                    <div className="w-full h-full rounded-lg overflow-hidden">
                        {userLatitude && userLongitude ? (
                            <Map
                                ref={mapRef} // Reference to Map component
                                initialViewState={{
                                    latitude: userLatitude,
                                    longitude: userLongitude,
                                    zoom: 12, // Adjust zoom level as needed
                                }}
                                mapStyle={mapStyle && mapStyle.toJS()}
                                styleDiffing
                                mapboxAccessToken={`${MAPBOX_TOKEN}`}
                                className="w-full h-full"
                            >
                                {/* Markers and other components go here */}
                                {filteredHomes.map((item) => (
                                    <Marker
                                        key={item.zpid} // Zillow Property ID
                                        longitude={item.longitude} // Assuming Zillow has 'longitude'
                                        latitude={item.latitude}   // Assuming Zillow has 'latitude'
                                        onClick={() => navigate(`/ listing / ${item.zpid} `)}
                                    >
                                        <div>
                                            <svg className="h-6 w-6" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <defs>
                                                    <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                                                        <stop offset="0%" style={{ stopColor: '#EC6227', stopOpacity: 1 }} />
                                                        <stop offset="50%" style={{ stopColor: '#E80458', stopOpacity: 1 }} />
                                                        <stop offset="100%" style={{ stopColor: '#5900B3', stopOpacity: 1 }} />
                                                    </linearGradient>
                                                </defs>
                                                <circle cx="10" cy="10" r="6" fill="url(#gradient1)" />
                                            </svg>
                                        </div>
                                    </Marker>
                                ))}
                            </Map>
                        ) : (
                            <div>Loading map...</div> // Placeholder while loading
                        )}

                    </div>
                </div>
                <aside className="w-1/4 bg-white rounded-lg h-full overflow-y-auto max-h-[100vh]">
                    <div className="pt-1 space-y-4 overflow-y-hidden">
                        {filteredHomes.map((property) => {
                            // console.log("Property", property);  // Check the structure of each property
                            return (
                                <ListingCard
                                    key={property.zpid}
                                    property={property}
                                    isHovered={hoveredProperty === property.zpid}
                                    onClick={() => handleMarkerClick(property)}
                                    className="mb-1"
                                    ref={(el) => (propertyRefs.current[property.zpid] = el)}
                                />
                            );
                        })}
                    </div>
                </aside>
            </div>
        </div >
    );
};

export default MapComponent;
