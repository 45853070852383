// import React, { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
// import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
// import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
// import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import IosShareIcon from '@mui/icons-material/IosShare';
// import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
// import { EmailShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
// import { Facebook as FacebookIcon, Twitter as TwitterIcon, LinkedIn as LinkedInIcon, Email as EmailIcon } from '@mui/icons-material';
// import PocketBaseContext from '../../../PocketBaseContext';
// import CalendarModal from './calendar';

// const ListingCard = ({ property, onClick }) => {
//     const imageUrl = `http://127.0.0.1:8090/api/files/8rg7vfrwv7amctx/${property.id}/${property.Image[0]}`;
//     const [isHovered, setIsHovered] = useState(false);
//     const [isFavorited, setIsFavorited] = useState(false);
//     const [showShareOptions, setShowShareOptions] = useState(false);
//     const pb = useContext(PocketBaseContext);
//     const user = pb?.authStore?.model; // Optional chaining to prevent null reference
//     const homeId = property.id;
//     const [showError, setShowError] = useState(false);
//     const [errorOpacity, setErrorOpacity] = useState(1);
//     const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
//     const [selectedHome, setSelectedHome] = useState(null);

//     useEffect(() => {
//         const checkIfLiked = async () => {
//             if (!user) return; // Early return if user is not available

//             try {
//                 const response = await pb.collection('Liked_Homes').getList(1, 1, {
//                     filter: `user_id="${user.id}" && home_id="${homeId}"`,
//                 });
//                 setIsFavorited(response.totalItems > 0);
//             } catch (error) {
//                 console.error('Error checking if home is liked:', error);
//             }
//         };
//         checkIfLiked();
//     }, [user, homeId, pb]);

//     const handleShareClick = () => {
//         setShowShareOptions(!showShareOptions);
//     };

//     const handleFavoriteClick = async (e) => {
//         e.stopPropagation();
//         e.preventDefault();

//         if (!user) {
//             alert('Please log in to like homes');
//             return;
//         }

//         try {
//             if (isFavorited) {
//                 const record = await pb.collection('Liked_Homes').getFirstListItem(`user_id="${user.id}" && home_id="${homeId}"`);
//                 await pb.collection('Liked_Homes').delete(record.id);
//                 setIsFavorited(false);
//                 console.log('Home removed from favorites successfully');
//             } else {
//                 await pb.collection('Liked_Homes').create({
//                     user_id: user.id,
//                     home_id: homeId,
//                 });
//                 setIsFavorited(true);
//                 console.log('Home saved to favorites successfully');
//             }
//         } catch (error) {
//             console.error('Error toggling favorite status:', error);
//         }
//     }

//     const openCalendarModal = () => {
//         if (!user) {
//             alert('Please Log In');
//         } else {
//             setSelectedHome(homeId);
//             setIsCalendarModalOpen(true);
//         }
//     };

//     const closeCalendarModal = () => setIsCalendarModalOpen(false);

//     return (
//         <div
//             className="relative overflow-hidden cursor-pointer transition-transform duration-300 ease-in-out rounded-lg mb-4 h-72"
//             onMouseEnter={() => setIsHovered(true)}
//             onMouseLeave={() => setIsHovered(false)}
//         >
//             <Link
//                 to={`/listing/${property.id}`}
//                 className={`no-underline ${isHovered ? 'transform scale-105 shadow-lg' : ''}`}
//                 onClick={onClick}
//             >
//                 <img
//                     src={imageUrl}
//                     alt={property.Address}
//                     className="w-full h-full object-cover rounded-lg"
//                 />

//                 {/* Price tag */}
//                 <div className="absolute top-3 right-3 bg-white text-black px-3 py-1 rounded-md shadow-lg">
//                     <p className="m-0 font-bold">${property.Price.toLocaleString()}</p>
//                 </div>

//                 {/* Property info */}
//                 <div className={`absolute bottom-0 w-full bg-black bg-opacity-30 p-3 text-white transition-all duration-300 ${isHovered ? 'h-auto' : 'h-12'}`}>
//                     <h6 className="m-0 truncate">{property.Address}</h6>

//                     {/* Info Tiles */}
//                     <div className={`mt-2 flex justify-between space-x-2 ${isHovered ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
//                         <div className="bg-white text-black p-2 rounded-md flex-1 text-center">
//                             <div className='flex flex-row items-center justify-center space-x-1'>
//                                 <span><HotelOutlinedIcon /></span>
//                                 <p className="m-0 text-s">Beds</p>
//                             </div>
//                             <p className="m-0 font-bold">{property.Bedrooms}</p>
//                         </div>
//                         <div className="bg-white text-black p-2 rounded-md flex-1 text-center">
//                             <div className='flex flex-row items-center justify-center space-x-1'>
//                                 <span><BathtubOutlinedIcon /></span>
//                                 <p className="m-0 text-s">Baths</p>
//                             </div>
//                             <p className="m-0 font-bold">{property.Bathrooms}</p>
//                         </div>
//                         <div className="bg-white text-black p-2 rounded-md flex-1 text-center">
//                             <div className='flex flex-row items-center justify-center space-x-1'>
//                                 <span><SquareFootOutlinedIcon /></span>
//                                 <p className="m-0 text-s">Sq Ft</p>
//                             </div>
//                             <p className="m-0 font-bold">{property.SQFT.toLocaleString()}</p>
//                         </div>
//                     </div>
//                 </div>
//             </Link>

//             {/* Like and Share button */}
//             <div
//                 className="absolute top-0 left-0 bg-white text-black px-3 py-2 rounded-br-lg flex items-center space-x-2"
//             >
//                 <span onClick={handleFavoriteClick} className='pr-2 cursor-pointer'>
//                     {isFavorited ? (
//                         <FavoriteIcon style={{ color: 'red', fill: 'red' }} />
//                     ) : (
//                         <FavoriteBorderOutlinedIcon style={{ color: 'red' }} />
//                     )}
//                 </span>
//                 <button onClick={handleShareClick} className="p-2 rounded-full hover:bg-gray-300">
//                     <IosShareIcon style={{ color: 'grey' }} />
//                 </button>
//                 {showShareOptions && (
//                     <div className="absolute top-full mt-2 bg-white shadow-lg p-4 rounded-lg flex gap-4">
//                         <FacebookShareButton url={window.location.href}>
//                             <FacebookIcon />
//                         </FacebookShareButton>
//                         <TwitterShareButton url={window.location.href}>
//                             <TwitterIcon />
//                         </TwitterShareButton>
//                         <LinkedinShareButton url={window.location.href}>
//                             <LinkedInIcon />
//                         </LinkedinShareButton>
//                         <EmailShareButton subject="Check this out!" body={`I thought you might like this property: ${property.Address} \n\nPrice: $${property.Price.toLocaleString()} \n\nSee more at: http://localhost:3000/listing/${property.id}`}>
//                             <EmailIcon />
//                         </EmailShareButton>
//                     </div>
//                 )}
//                 <button onClick={openCalendarModal} className="p-2 rounded-full hover:bg-gray-300">
//                     <CalendarMonthOutlinedIcon style={{ color: "grey" }} />
//                 </button>
//                 {/* Error Message */}
//                 {showError && (
//                     <div className={`bg-red-100 text-red-800 border border-red-500 p-4 text-center transition-opacity duration-500`} style={{ opacity: errorOpacity }}>
//                         <h1 className="text-lg font-bold">Please log in to access this feature.</h1>
//                     </div>
//                 )}
//             </div>
//             <CalendarModal isOpen={isCalendarModalOpen} onClose={closeCalendarModal} homeId={selectedHome} />
//         </div>
//     );
// };

// export default ListingCard;


import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';
import CalendarModal from './calendar';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PocketBaseContext from '../../../PocketBaseContext';

const ListingCard = ({ property }) => {
    const [isFavorited, setIsFavorited] = useState(false);
    const imageUrl = property.imgSrc ? property.imgSrc : '/default-image.jpg';
    const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
    const pb = useContext(PocketBaseContext);
    const user = pb?.authStore?.model;
    const [showError, setShowError] = useState(false);
    const [errorOpacity, setErrorOpacity] = useState(1);
    const [selectedHome, setSelectedHome] = useState(null);
    const [showShareOptions, setShowShareOptions] = useState(false);

    useEffect(() => {
        const checkIfLiked = async () => {
            if (!user) return; // Early return if user is not available

            try {
                const response = await pb.collection('Liked_Homes').getList(1, 1, {
                    filter: `user_id="${user.id}" && home_id="${property.zpid}"`,
                });
                setIsFavorited(response.totalItems > 0);
            } catch (error) {
                console.error('Error checking if home is liked:', error);
            }
        };
        checkIfLiked();
    }, [user, property.zpid, pb]);

    const handleShareClick = () => {
        setShowShareOptions(!showShareOptions);
    };

    const handleFavoriteClick = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!user) {
            alert('Please log in to like homes');
            return;
        }

        try {
            if (isFavorited) {
                const record = await pb.collection('Liked_Homes').getFirstListItem(`user_id="${user.id}" && home_id="${property.zpid}"`);
                await pb.collection('Liked_Homes').delete(record.id);
                setIsFavorited(false);
                console.log('Home removed from favorites successfully');
            } else {
                await pb.collection('Liked_Homes').create({
                    user_id: user.id,
                    home_id: property.zpid,
                });
                setIsFavorited(true);
                console.log('Home saved to favorites successfully');
            }
        } catch (error) {
            console.error('Error toggling favorite status:', error);
        }
    }

    const openCalendarModal = () => {
        if (!user) {
            alert('Please Log In');
        } else {
            setSelectedHome(property.zpid);
            setIsCalendarModalOpen(true);
        }
    };

    const closeCalendarModal = () => setIsCalendarModalOpen(false);

    return (
        <Link to={`/listing/${property.zpid}`}>
            <div className="relative overflow-hidden rounded-lg mb-2 h-74 border border-gray-300 transition-shadow duration-300 hover:shadow-xl">
                <img
                    src={imageUrl}
                    alt={property.address}
                    className="w-full h-40 object-cover rounded-t-lg transition-transform duration-300 hover:scale-105"
                />
                <div className="p-3 pb-16"> {/* Increase bottom padding here */}
                    <h5 className="font-bold truncate">{property.address || 'Address not available'}</h5>
                    <div className="flex justify-center">
                        <p className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3]">
                            {property.price ? `$${property.price.toLocaleString()}` : 'Price not available'}
                        </p>
                    </div>




                    <div className="flex justify-around mt-2 text-gray-600">
                        <div className="flex items-center space-x-2 hover:text-black hover:border-b-2 hover:border-black">
                            <HotelOutlinedIcon fontSize="medium" />
                            <p className="text-md font-semibold">{property.bedrooms || 'N/A'}</p>
                        </div>
                        <div className="flex items-center space-x-2 hover:text-black hover:border-b-2 hover:border-black">
                            <BathtubOutlinedIcon fontSize="medium" />
                            <p className="text-md font-semibold">{property.bathrooms || 'N/A'}</p>
                        </div>
                        <div className="flex items-center space-x-2 hover:text-black hover:border-b-2 hover:border-black">
                            <SquareFootOutlinedIcon fontSize="medium" />
                            <p className="text-md font-semibold">{property.livingArea ? property.livingArea.toLocaleString() : 'N/A'}</p>
                        </div>
                    </div>

                </div>

                {/* Action Buttons */}
                <div className="absolute bottom-0 left-0 right-0 p-2 flex justify-between bg-white bg-opacity-90 transition-opacity duration-300 hover:opacity-100 opacity-70">
                    <Tooltip title={isFavorited ? "Remove from Favorites" : "Add to Favorites"}>
                        <span onClick={handleFavoriteClick} className="cursor-pointer">
                            {isFavorited ? (
                                <FavoriteIcon style={{ color: 'red' }} className='cursor-pointer ' />
                            ) : (
                                <FavoriteBorderOutlinedIcon />
                            )}
                        </span>
                    </Tooltip>

                    <Tooltip title="Share">
                        <span className="cursor-pointer">
                            <ShareIcon />
                        </span>
                    </Tooltip>

                    <Tooltip title="Add to Calendar">
                        <button onClick={openCalendarModal} className="p-2 rounded-full ">
                            <CalendarMonthOutlinedIcon />
                        </button>
                    </Tooltip>
                </div>
                {showError && (
                    <div className={`bg-red-100 text-red-800 border border-red-500 p-4 text-center transition-opacity duration-500`} style={{ opacity: errorOpacity }}>
                        <h1 className="text-lg font-bold">Please log in to access this feature.</h1>
                    </div>
                )}
                <CalendarModal isOpen={isCalendarModalOpen} onClose={closeCalendarModal} />
            </div>
        </Link>
    );
};

export default ListingCard;





