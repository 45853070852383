import React, { useState, useEffect, useContext, useRef } from 'react';
import { ArrowBack, Map as MapIcon, CameraAlt } from '@mui/icons-material';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios'; // Import axios
import PocketBaseContext from '../../PocketBaseContext';
import MortgageCalc from './components/mortgage';
import GradientButton from '../../components/Reusables/gradient_button';
import ClipLoader from 'react-spinners/ClipLoader';
import { Map, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import TaxAndPriceHistoryGraph from './components/taxHistory';
import CalendarModal from '../Map/map_components/calendar';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN_API;

export default function PropertyListing() {
    const [tabValue, setTabValue] = useState('overview');
    const { propertyId } = useParams();
    const [propertyData, setPropertyData] = useState(null);
    const [propertyImages, setPropertyImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null); // Track index for modal
    const [calendarModalOpen, setCalendarModalOpen] = useState(false); // State to track if calendar modal is open
    const pb = useContext(PocketBaseContext);
    const overviewRef = useRef(null);
    const detailsRef = useRef(null);
    const taxRef = useRef(null);
    const schoolsRef = useRef(null);
    const mortgageRef = useRef(null);
    const mapRef = useRef(null);
    const [mapStyle, setMapStyle] = useState(null);
    const taxHistory = propertyData?.taxHistory || [];
    const pricehistory = propertyData?.priceHistory || [];

    // Map tabs to their corresponding refs
    const sectionRefs = {
        overview: overviewRef,
        details: detailsRef,
        tax: taxRef,
        schools: schoolsRef,
        mortgage: mortgageRef,
    };

    const handleTabChange = (event, tab) => {
        setTabValue(tab);

        // Get the current position of the target section
        const targetSection = sectionRefs[tab]?.current;
        if (targetSection) {
            // Calculate the offset (e.g., 100 pixels)
            const offset = 100; // Adjust this value as needed

            // Scroll to the desired position
            const targetPosition = targetSection.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({ top: targetPosition, behavior: 'smooth' });
        }
    };
    const [homes, setHomes] = useState([]); // Store property data for map markers
    const [userLocation, setUserLocation] = useState({ latitude: 37.7749, longitude: -122.4194 }); // Default location


    // Fetch property data and images
    useEffect(() => {
        const fetchPropertyData = async () => {
            const propertyOptions = {
                method: 'GET',
                url: 'https://zillow-com1.p.rapidapi.com/property',
                params: { zpid: propertyId }, // Use dynamic zpid from the route
                headers: {
                    'x-rapidapi-key': 'f8ccc0b787mshafd5b0cf38ad46ep1f27f1jsn9699ae0d879e',
                    'x-rapidapi-host': 'zillow-com1.p.rapidapi.com',
                },
            };

            const imageOptions = {
                method: 'GET',
                url: 'https://zillow-com1.p.rapidapi.com/images',
                params: { zpid: propertyId }, // Fetch images using zpid
                headers: {
                    'x-rapidapi-key': 'f8ccc0b787mshafd5b0cf38ad46ep1f27f1jsn9699ae0d879e',
                    'x-rapidapi-host': 'zillow-com1.p.rapidapi.com',
                },
            };

            try {
                // Make both API calls in parallel
                const [propertyResponse, imageResponse] = await Promise.all([
                    axios.request(propertyOptions),
                    axios.request(imageOptions),
                ]);

                setPropertyData(propertyResponse.data); // Set property details
                console.log(propertyResponse.data);
                setPropertyImages(imageResponse.data.images); // Set property images
                setLoading(false);
            } catch (error) {
                console.error('Error fetching property data or images:', error);
                setError('Failed to load property data or images');
                setLoading(false);
            }
        };

        fetchPropertyData();
    }, [propertyId]);

    const openImageModal = (index) => {
        console.log("Opening modal with image index:", index);
        setSelectedImageIndex(index);
    };


    const closeImageModal = () => {
        setSelectedImageIndex(null); // Close modal
    };

    const handleNextImage = () => {
        setSelectedImageIndex((prevIndex) => {
            const newIndex = prevIndex === propertyImages.length - 1 ? 0 : prevIndex + 1;
            // console.log("Next image index:", newIndex);
            return newIndex;
        });
    };

    const handlePrevImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === 0 ? propertyImages.length - 1 : prevIndex - 1
        ); // Go to previous image or loop to the last
    };

    const handleSeeTourAvailability = () => {
        console.log("Click")
        setCalendarModalOpen(true);
    };

    if (loading) {
        return <div className="flex items-center justify-center h-screen">
            <ClipLoader color="#36d7b7" />
        </div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="p-4">
            {/* Full-width Image Section */}
            <div className="relative w-[80vw] mx-auto overflow-hidden mb-4"> {/* Adjusted to 80% width and centered */}
                <div className="w-full h-1/4 overflow-hidden">
                    <div className="flex overflow-x-auto space-x-4">
                        {propertyImages.map((image, index) => (
                            <div
                                key={index}
                                style={{ minWidth: '300px', height: '400px' }}
                                className="flex-shrink-0 overflow-hidden rounded-lg"
                                onClick={() => openImageModal(index)} // Open modal with clicked image index
                            >
                                <img
                                    src={image}
                                    alt={`Property Image ${index + 1}`}
                                    className="w-full h-full object-cover rounded-lg cursor-pointer"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Modal for Full Image */}
            {selectedImageIndex !== null && propertyImages.length > 0 && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={closeImageModal} // Close modal when clicking outside the image
                >
                    <div className="relative" onClick={(e) => e.stopPropagation()}> {/* Prevent close on image click */}
                        <img
                            src={propertyImages[selectedImageIndex]}
                            alt={`Selected Property Image ${selectedImageIndex + 1}`}
                            className="max-w-full max-h-full rounded-lg"
                        />

                        {/* Close Button */}
                        <button
                            onClick={closeImageModal}
                            className="absolute top-2 right-2 text-white bg-black bg-opacity-50 rounded-full p-2 z-60"
                            style={{ zIndex: 60 }} // Ensure the close button is on top
                        >
                            Close
                        </button>

                        {/* Navigation Buttons */}
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handlePrevImage();
                            }}
                            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 rounded-full p-3 z-60"
                            style={{ zIndex: 60 }} // Ensure prev button is on top
                        >
                            Prev
                        </button>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleNextImage();
                            }}
                            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 rounded-full p-3 z-60"
                            style={{ zIndex: 60 }} // Ensure next button is on top
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}

            {/* Content within max-w-5xl */}
            <div className="p-4 max-w-5xl mx-auto">
                {/* Buttons and Tabs */}
                <div className="mb-4">
                    <Link to={'/map'}>
                        <GradientButton className="pb-2">Back to Map</GradientButton>
                    </Link>
                    <div className="flex space-x-4 overflow-x-auto pt-4">
                        {['overview', 'details', 'tax', 'schools', 'mortgage'].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => handleTabChange(null, tab)}
                                className={`px-4 py-2 rounded-t-lg ${tabValue === tab ? 'bg-primary-gradient text-white' : 'bg-gray-200 text-gray-700'}`}
                            >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Other sections (e.g., Property Info, Map, Mortgage Calculator, etc.) */}
                <div ref={overviewRef}>
                    {/* Action Buttons */}
                    <div className="flex mb-4 space-x-4">
                        <button className="bg-primary-gradient text-white px-4 py-2 rounded flex items-center">
                            <MapIcon className="mr-2" />
                            Street View
                        </button>
                        <button className="bg-gray-200 text-gray-700 px-4 py-2 rounded flex items-center">
                            <CameraAlt className="mr-2" />
                            {propertyImages.length} photos
                        </button>
                    </div>

                    {/* Property Information */}
                    <div className="mb-4 text-gray-600">
                        <p>Listed by {propertyData?.brokerageName || 'Unknown Agent'}</p>
                    </div>

                    <div className="flex flex-row justify-between bg-white p-4 rounded-lg shadow-md mb-4">
                        <div>
                            <div className="flex items-center mb-2">
                                <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
                                <p className="text-green-500 font-bold">{propertyData?.status || 'FOR SALE - ACTIVE'}</p>
                            </div>
                            <h1 className="text-2xl font-bold mb-1">{propertyData?.address.streetAddress || 'Unknown Address'}</h1>
                            <div className='flex flex-row'>
                                <h1 className="text-l mb-2">{propertyData?.address.city || 'Unknown Address'},</h1>
                                <h1 className="text-l mb-2">&nbsp;{propertyData?.address.state || 'Unknown Address'}</h1>

                            </div>
                            <div>
                                <h2 className="text-2xl font-bold">${propertyData?.price.toLocaleString() || 'N/A'}</h2>
                                <p className="text-gray-600">Est. {propertyData?.mortgage?.estimate || 'N/A'}/mo</p>
                            </div>
                        </div>
                        <div className="flex justify-between items-center space-x-4">

                            <div className='flex flex-col space-y-2'>
                                <GradientButton className="py-2 px-4" onClick={handleSeeTourAvailability}>
                                    See Tour Availability
                                </GradientButton>
                                <GradientButton className="py-2 px-4">
                                    Message Listing Agent
                                </GradientButton>
                            </div>
                        </div>
                    </div>


                    {/* Property Information */}
                    <div className="mb-4 text-gray-600">
                        <p>Listed by {propertyData?.brokerageName || 'Unknown Agent'}</p>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-md mb-4">
                        <div className="min-h-[300px]">
                            <Map
                                ref={mapRef}
                                initialViewState={{ latitude: propertyData.latitude, longitude: propertyData.longitude, zoom: 14 }}
                                mapStyle="mapbox://styles/mapbox/streets-v11"
                                style={{ width: '100%', height: '400px' }}
                                mapboxAccessToken={MAPBOX_TOKEN}
                                onLoad={() => console.log("Map has loaded successfully")}
                                onError={(error) => console.error("Error loading the map", error)}
                            >
                                <Marker
                                    latitude={propertyData.latitude}
                                    longitude={propertyData.longitude}
                                    offsetLeft={-20}
                                    offsetTop={-10}
                                >
                                    <div>
                                        <svg className="h-6 w-6" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                                                    <stop offset="0%" style={{ stopColor: '#EC6227', stopOpacity: 1 }} />
                                                    <stop offset="50%" style={{ stopColor: '#E80458', stopOpacity: 1 }} />
                                                    <stop offset="100%" style={{ stopColor: '#5900B3', stopOpacity: 1 }} />
                                                </linearGradient>
                                            </defs>
                                            <circle cx="10" cy="10" r="6" fill="url(#gradient1)" />
                                        </svg>
                                    </div>
                                </Marker>
                            </Map>
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 h-80 overflow-y-auto" ref={detailsRef}>
                        <div className="grid grid-cols-3 gap-4 text-lg overflow-y">
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.bedrooms || 'N/A'}</p>
                                <p className="text-gray-500">Beds</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.bathrooms || 'N/A'}</p>
                                <p className="text-gray-500">Baths</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.livingArea.toLocaleString() || 'N/A'}</p>
                                <p className="text-gray-500">Sq Ft</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.resoFacts.lotSize || 'N/A'}</p>
                                <p className="text-gray-500">Lot Size</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.yearBuilt || 'N/A'}</p>
                                <p className="text-gray-500">Year Built</p>
                            </div>
                            {propertyData?.resoFacts.parkingFeatures && propertyData.resoFacts.parkingFeatures.length > 0 ? (
                                <div className='hover:underline cursor-default'>
                                    <p className="font-bold">{propertyData.resoFacts.parkingFeatures[0]}</p>
                                    <p className="text-gray-500">Parking</p>
                                </div>
                            ) : (
                                <div className='hover:underline cursor-default'>
                                    <p className="font-bold">N/A</p>
                                    <p className="text-gray-500">Parking</p>
                                </div>
                            )}
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.resoFacts.associationFee || 'No HOA'}</p>
                                <p className="text-gray-500">HOA Fee</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.resoFacts.architecturalStyle || 'N/A'}</p>
                                <p className="text-gray-500">Property Type</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.resoFacts.exteriorFeatures || 'N/A'}</p>
                                <p className="text-gray-500">Exterior Features</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.resoFacts.interiorFeatures || 'N/A'}</p>
                                <p className="text-gray-500">Interior Features</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.resoFacts.waterSource || 'N/A'}</p>
                                <p className="text-gray-500">View</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.resoFacts.poolFeatures || 'N/A'}</p>
                                <p className="text-gray-500">Pool Features</p>
                            </div>
                            <div className='hover:underline cursor-default'>
                                <p className="font-bold">{propertyData?.resoFacts.garageFeatures || 'N/A'}</p>
                                <p className="text-gray-500">Garage Features</p>
                            </div>
                        </div>
                    </div>

                    {/* Other property info and content here... */}
                    <div className="bg-white p-4 rounded-lg shadow-md mt-4">
                        <h2 className="text-xl font-bold mb-2">About this home</h2>
                        <p className="text-gray-600">{propertyData?.description || 'No description available.'}</p>
                    </div>

                    {/* Mortgage Calculator */}
                    <div className="bg-white p-4 rounded-lg shadow-md mt-4" ref={mortgageRef}>
                        <h2 className="text-xl font-bold mb-2">Mortgage Calculator</h2>
                        <MortgageCalc property={propertyData} />
                    </div>

                    {/* Tax Graph */}
                    <div className="bg-white p-4 rounded-lg shadow-md mb-4 h-full overflow-y-auto mt-4" ref={taxRef}>
                        <TaxAndPriceHistoryGraph taxHistory={taxHistory} priceHistory={pricehistory} />
                    </div>
                </div>
            </div>
            {calendarModalOpen && (
                <CalendarModal
                    isOpen={calendarModalOpen}
                    onClose={() => setCalendarModalOpen(false)}
                />
            )}
        </div >
    );
}

