import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import GradientButton from '../Reusables/gradient_button';
import ContactForm from './contactform';
import { StickyNote2 } from '@mui/icons-material';
import ScrollStory from './ScrollStory/scrollstory';

// Variants for Framer Motion animations
const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 1, ease: 'easeOut' },
    },
    exit: {
        opacity: 0,
        y: -50,
        transition: { duration: 0.5, ease: 'easeIn' },
    },
};

const arrowVariants = {
    initial: { y: 0, opacity: 1 },
    animate: {
        y: [0, -10, 0],
        transition: {
            duration: 1,
            repeat: Infinity,
        },
    },
    hidden: { opacity: 0, transition: { duration: 0.3 } },
};

const DesktopLandingPage = () => {
    const [showArrow, setShowArrow] = useState(true);
    const [scrollY, setScrollY] = useState(0);
    const arrowControls = useAnimation();
    const scrollableRef = useRef(null);
    const [showContact, setShowContact] = useState(false);
    const [hoveredCard, setHoveredCard] = useState(null);
    const [isSticky, setIsSticky] = useState(true);
    const [isVisible, setIsVisible] = useState(true); // Control visibility for animation

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableRef.current) {
                const currentScrollY = scrollableRef.current.scrollTop;
                setScrollY(currentScrollY);

                const lastSection = document.querySelector('#last-section');
                if (lastSection) {
                    const lastSectionRect = lastSection.getBoundingClientRect();
                    const lastSectionBottom = lastSectionRect.bottom;

                    // Determine if the last section is still in the viewport
                    const isCurrentlySticky = lastSectionBottom > window.innerHeight;
                    setIsSticky(isCurrentlySticky);

                    // Determine if the last section is completely out of the viewport
                    const isLastSectionOutOfView = lastSectionBottom <= 0;

                    // Determine if the last section is partially in view
                    const isLastSectionPartiallyInView = lastSectionRect.top < window.innerHeight && lastSectionRect.bottom > 0;

                    // Set visibility based on scroll position
                    if (isLastSectionPartiallyInView) {
                        // If the last section is in view, make it visible
                        setIsVisible(true);
                    } else if (isLastSectionOutOfView) {
                        // If the last section is out of view, fade out the component
                        setIsVisible(false);
                    }
                }
            }
        };

        const scrollableElement = scrollableRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollableRef]);




    useEffect(() => {
        if (showArrow) {
            arrowControls.start('animate');
        } else {
            arrowControls.start('hidden');
        }
    }, [showArrow, arrowControls]);

    const handleContact = () => {
        setShowContact(!showContact);
        console.log("Contact button clicked");
    }

    // const handleCardClick = (cardIndex) => {
    //     setFocusedCard(cardIndex);
    // };

    return (
        <div className="flex flex-col h-screen">
            {/* Header */}
            <div className="flex flex-row justify-between w-full p-4 z-10 bg-white max-h-[25%]">
                {/* Left Column */}
                <div className="flex flex-col items-center w-1/3">
                    <img
                        src="/logo.png"
                        alt="Realti Logo"
                        className="w-20 mb-2 mt-2 transform hover:scale-105 transition-transform duration-300"
                    />
                    {/* Header */}
                    <h1 className="text-4xl font-extrabold mb-1 tracking-wide text-center">
                        Realti
                    </h1>
                    {/* Static tagline */}
                    <p className="text-xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text font-bold tracking-tight text-center mb-2">
                        Safe. Easy. Transparent.
                    </p>
                </div>

                {/* Middle Column - Dynamic Text */}
                <div className="mt-2 text-center text-sm w-1/3 flex-1 flex flex-col justify-center">
                    <div className="relative flex flex-col items-center justify-center w-full">
                        <div className="text-2xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text font-semibold transition-colors duration-300 h-24 flex flex-col items-center justify-center min-w-[300px]">
                            {/* Dynamic Text */}
                            <div className="flex flex-col h-full items-center">
                                <p className="mb-2 text-black">Built for:</p>
                                <p>
                                    {scrollY < 400 && 'Homeowners'}
                                    {scrollY >= 400 && scrollY < 800 && 'Agents'}
                                    {scrollY >= 800 && scrollY < 1000 && 'Buyers'}
                                    {scrollY >= 1000 && scrollY < 1200 && 'Sellers'}
                                    {scrollY >= 1200 && scrollY < 1400 && 'Attorneys'}
                                    {scrollY >= 1400 && scrollY < 1600 && 'Stagers'}
                                    {scrollY >= 1600 && scrollY < 1800 && 'Appraisers'}
                                    {scrollY >= 1800 && scrollY < 2000 && 'Banks'}
                                    {scrollY >= 2000 && scrollY < 2200 && 'Insurance Companies'}
                                    {scrollY >= 2200 && scrollY < 2400 && 'Moving Companies'}
                                    {scrollY >= 2400 && scrollY < 2600 && 'Cleaning Services'}
                                    {scrollY >= 2600 && scrollY < 2800 && 'Investors'}
                                    {scrollY >= 2800 && 'Everyone'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-center w-1/3 mb-8">
                    <img
                        src="/landing_page/Final.svg"
                        alt="Realti House"
                        className="w-52 rounded-lg"
                    />
                </div>
            </div>
            {/* Right Column - Scrollable */}
            <div ref={scrollableRef} className="w-full overflow-y-auto relative">
                {/* <motion.div
                    className="fixed right-8 bottom-8"
                    variants={arrowVariants}
                    initial="initial"
                    animate={arrowControls}
                >
                    <div className="flex justify-center items-center h-full w-16 opacity-70">
                        <img src="/landing_page/Landing arrows.png" alt="Centered Image" className="w-auto h-auto" />
                    </div>
                </motion.div> */}

                <div className="text-center mt-28 mb-6 items-center">
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5, duration: 1.5 }}
                        className='text-6xl font-bold bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text'
                    >
                        A Major Listing Service that incorporates people and tools.
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5, duration: 1.5 }}
                        className="flex justify-center space-x-10 mt-10"
                    >
                        <GradientButton onClick={() => scrollableRef.current.scrollTo({ top: 400, behavior: 'smooth' })}> Learn More</GradientButton>
                        <GradientButton onClick={() => scrollableRef.current.scrollTo({ top: 4000, behavior: 'smooth' })}>Join Realti</GradientButton>
                    </motion.div>
                </div>

                {/* About Section */}
                <motion.section
                    className="h-screen flex items-center p-16 bg-white"
                    variants={sectionVariants}
                    initial="hidden"
                    whileInView="visible"
                    exit="exit"
                    viewport={{ once: false, amount: 0.5 }}
                >
                    <div className="relative">
                        <div className="absolute -inset-2 rounded-xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] opacity-75 blur-lg"></div>
                        <div className="relative flex items-center justify-between p-10 rounded-lg bg-white">
                            <div className="flex flex-col justify-center w-1/2 pr-4">
                                <h2 className="text-6xl font-bold mb-6">
                                    <span className="bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text">
                                        Realti
                                    </span>
                                </h2>
                                <p className="text-xl mb-6 text-left">
                                    Realti is an all-in-one platform for every step of your real estate transaction.
                                </p>
                            </div>
                            <div className="w-1/2 flex justify-center items-center">
                                <img src="/landing_page/Town 1.png" alt="Realti Visual" className="" />
                            </div>
                        </div>
                    </div>
                </motion.section>

                {/* {Realti in a Nutshell} */}
                <div className="text-center mt-32 items-center">
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5, duration: 1.5 }}
                        className='text-6xl font-bold bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text'
                    >
                        What makes Realti the best
                    </motion.div>
                </div>

                {/* Overview */}
                <motion.section
                    className="h-screen flex items-start p-16 bg-white"
                    variants={sectionVariants}
                    initial="hidden"
                    whileInView="visible"
                    exit="exit"
                    viewport={{ once: false, amount: 0.5 }}
                >
                    <div className="relative w-full mx-auto">
                        {/* Gradient Background */}
                        <div className="absolute -inset-2 rounded-xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] opacity-75 blur-lg"></div>

                        {/* Main Content */}
                        <div className="relative flex justify-center items-center p-10 rounded-lg bg-white w-full h-full">
                            {/* Cards Container */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-10 pb-0">
                                {/* Syndications */}
                                <div className="flex flex-col items-center h-2/3">
                                    <div className={`transition-opacity duration-300 mt-2`}>
                                        <p className="text-2xl text-black text-center px-4 underline">
                                            Syndications
                                        </p>
                                    </div>
                                    <motion.div
                                        className={`relative flex flex-col items-center p-4 shadow-lg rounded-lg transition-all duration-300 bg-white w-full transform`}
                                        onMouseEnter={() => setHoveredCard(1)}
                                        onMouseLeave={() => setHoveredCard(null)}
                                        layout
                                    >
                                        <img
                                            src="/landing_page/cards/Syndications.png"
                                            alt="Syndications"
                                            className={`w-full object-contain rounded-lg transition-transform duration-300 ease-in-out ${hoveredCard === 1 ? 'h-60' : 'h-48'}`}
                                        />
                                    </motion.div>
                                    {/* Use fixed height to reserve space */}
                                    <div className={`transition-opacity duration-300 mt-2 h-16 ${hoveredCard === 1 ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
                                        <p className="text-lg text-gray-700 text-center px-4 pt-2">
                                            Syndications to numerous external platforms, maximizing exposure for every property.
                                        </p>
                                    </div>
                                </div>

                                {/* Tools */}
                                <div className="flex flex-col items-center h-2/3">
                                    <div className={`transition-opacity duration-300 mt-2`}>
                                        <p className="text-2xl text-black text-center px-4 underline">
                                            Tools
                                        </p>
                                    </div>
                                    <motion.div
                                        className={`relative flex flex-col items-center p-4 shadow-lg rounded-lg transition-all duration-300 bg-white w-full transform`}
                                        onMouseEnter={() => setHoveredCard(2)}
                                        onMouseLeave={() => setHoveredCard(null)}
                                        layout
                                    >
                                        <img
                                            src="/landing_page/cards/Tools.png"
                                            alt="Tools"
                                            className={`w-full object-contain rounded-lg transition-transform duration-300 ease-in-out ${hoveredCard === 2 ? 'h-60' : 'h-48'}`}
                                        />
                                    </motion.div>
                                    <div className={`transition-opacity duration-300 mt-2 h-16 ${hoveredCard === 2 ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
                                        <p className="text-lg text-gray-700 text-center px-4 pt-2">
                                            Tools for documents, scheduling, and escrows to make every transaction transparent and efficient.
                                        </p>
                                    </div>
                                </div>

                                {/* UI */}
                                <div className="flex flex-col items-center h-2/3">
                                    <div className={`transition-opacity duration-300 mt-2`}>
                                        <p className="text-2xl text-black text-center px-4 underline">
                                            Interface
                                        </p>
                                    </div>
                                    <motion.div
                                        className={`relative flex flex-col items-center p-4 shadow-lg rounded-lg transition-all duration-300 bg-white w-full transform`}
                                        onMouseEnter={() => setHoveredCard(3)}
                                        onMouseLeave={() => setHoveredCard(null)}
                                        layout
                                    >
                                        <img
                                            src="/landing_page/cards/Dashboard.png"
                                            alt="Interface"
                                            className={`w-full object-contain rounded-lg transition-transform duration-300 ease-in-out ${hoveredCard === 3 ? 'h-60' : 'h-48'}`}
                                        />
                                    </motion.div>
                                    <div className={`transition-opacity duration-300 mt-2 h-16 ${hoveredCard === 3 ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
                                        <p className="text-lg text-gray-700 text-center px-4 pt-2">
                                            Best-in-class interface designed to simplify the complexities of real estate transactions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.section>


                <div className={`text-center mt-32 items-center pb-5 ${isSticky ? 'sticky top-0 z-50 bg-white' : 'relative'}`}>
                    <motion.div
                        initial={{ opacity: 0, y: -50 }} // Start with invisible and moved up
                        animate={{ opacity: isSticky ? 1 : 0, y: isSticky ? 0 : -50 }}    // Animate to visible and move to position based on isSticky
                        transition={{ duration: 0.5 }}
                        className='text-6xl font-bold bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text'
                    >
                        Specific features that separate Realti
                    </motion.div>
                </div>
                {/* Features Section */}
                <motion.section
                    className="h-screen flex items-center p-16 bg-white"
                    variants={sectionVariants}
                    initial="hidden"
                    whileInView="visible"
                    exit="exit"
                    viewport={{ once: false, amount: 0.5 }}
                >
                    <div className="relative">
                        <div className="absolute -inset-2 rounded-xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] opacity-75 blur-lg"></div>
                        <div className="relative flex items-center justify-between p-10 rounded-lg bg-white">
                            <div className="flex flex-col justify-center w-1/2 pr-4">
                                <h2 className="text-6xl font-bold mb-6">
                                    <span className="bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text">
                                        Schedule with Ease
                                    </span>
                                </h2>
                                <p className="text-xl mb-6 text-left">
                                    Realti has built-in scheduling components for everyone to be on the same page. No more back and forth with realtors for showings, inspections, and walk-throughs. With Realti, scheduling is a breeze.
                                </p>
                            </div>
                            <div className="w-1/4 flex justify-center items-center">
                                <img src="/landing_page/Schedule.svg" alt="Realti Visual" className="" />
                            </div>
                        </div>
                    </div>
                </motion.section>

                {/* Services Section */}
                <motion.section
                    className="h-screen flex items-center p-16 bg-white"
                    variants={sectionVariants}
                    initial="hidden"
                    whileInView="visible"
                    exit="exit"
                    viewport={{ once: false, amount: 0.5 }}
                >
                    <div className="relative">
                        <div className="absolute -inset-2 rounded-xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] opacity-75 blur-lg"></div>
                        <div className="relative flex items-center justify-between p-10 rounded-lg bg-white">
                            <div className="flex flex-col justify-center w-1/2 pr-4">
                                <h2 className="text-6xl font-bold mb-6">
                                    <span className="bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text">
                                        Send Money Safely
                                    </span>
                                </h2>
                                <p className="text-xl mb-6 text-left">
                                    Monetary transfers are simple, easy, and secure with Realti. See how and where your money is at all times. No more sending checks over emails or links from agents.
                                </p>
                            </div>
                            <div className="w-1/4 flex justify-center items-center">
                                <img src="/landing_page/Money.svg" alt="Realti Visual" className="" />
                            </div>
                        </div>
                    </div>
                </motion.section>

                {/* Document Section */}
                <motion.section
                    className="h-screen flex items-center p-16 bg-white"
                    variants={sectionVariants}
                    initial="hidden"
                    whileInView="visible"
                    exit="exit"
                    id="last-section"
                    viewport={{ once: false, amount: 0.5 }}
                >
                    <div className="relative">
                        <div className="absolute -inset-2 rounded-xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] opacity-75 blur-lg"></div>
                        <div className="relative flex items-center justify-between p-10 rounded-lg bg-white">
                            <div className="flex flex-col justify-center w-1/2 pr-4">
                                <h2 className="text-6xl font-bold mb-6">
                                    <span className="bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text">
                                        Sign, Store, and Share Documents Transparently
                                    </span>
                                </h2>
                                <p className="text-xl mb-6 text-left">
                                    Document signings, storage, sharing, and versions are all in one place. See the most up-to-date documents related to your transaction and who has access to each one.
                                </p>
                            </div>
                            <div className="w-1/4 flex justify-center items-center">
                                <img src="/landing_page/Documents.svg" alt="Realti Visual" className="" />
                            </div>
                        </div>
                    </div>
                </motion.section>

                {/* {Why are we doing this?} */}
                {/* <div className="text-center mt-32 items-center">
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5, duration: 1.5 }}
                        className='text-6xl h-40 font-bold bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text'
                    >
                        Why Are We Doing This?
                    </motion.div>
                </div>
                <ScrollStory /> */}


                {/* Contact Section */}
                <motion.section
                    className="h-screen flex items-center p-16 bg-white"
                    variants={sectionVariants}
                    initial="hidden"
                    whileInView="visible"
                    exit="exit"
                    viewport={{ once: false, amount: 0.5 }}
                >
                    <div className="relative">
                        {!showContact && (
                            <div className="absolute -inset-2 rounded-xl bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] opacity-75 blur-lg"></div>
                        )}

                        <div className="relative flex items-center justify-around p-10 rounded-lg bg-white">
                            <div className="flex flex-col justify-center w-1/2 pr-4">
                                <h2 className="text-6xl font-bold mb-6">
                                    <span className="bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-transparent bg-clip-text">
                                        Contact Us
                                    </span>
                                </h2>
                                <p className="text-xl mb-6 text-left">
                                    Please join our waitlist as we build out the best product for the real estate industry or contact us with any questions.
                                </p>
                            </div>
                            <GradientButton className="bg-gradient-to-r from-[#EC6227] via-[#E80458] to-[#5900B3] text-white font-bold py-4 px-8 rounded-full" onClick={handleContact}>
                                Contact US
                            </GradientButton>
                        </div>

                        {showContact && (
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: showContact ? 1 : 0, y: showContact ? 0 : 50 }}
                                transition={{ duration: 1.5, ease: "easeInOut" }}
                            >
                                <motion.div
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: showContact ? 1 : 0, scale: showContact ? 1 : 0.9 }}
                                    transition={{ duration: 0.5, ease: "easeInOut" }}
                                    className='flex justify-center items-center'
                                >
                                    <ContactForm />
                                </motion.div>
                            </motion.div>
                        )}
                    </div>
                </motion.section>
            </div >
        </div >

    );
};

export default DesktopLandingPage;
