import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GradientButton from '../Reusables/gradient_button';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ProgressModal from './dash_components/addpropmodal';
import Favorites from './dash_components/favorites';
import Profile from './dash_components/profile';
import MessageInput from './dash_components/messages';
import PocketBaseContext from '../../PocketBaseContext';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CalendarComponent from './dash_components/calendar';
import DashboardComponent from './dash_components/dash_component';

// Placeholder components for each view
// const DashboardComponent = () => <DashboardComponent />;
const MessageComponent = ({ user }) => <MessageInput Sender_Id={user.id} />;
const MyPropertiesComponent = () => <div>My Properties Component</div>;
const SavedSearchComponent = () => <div>Saved Search Component</div>;
const CalendarComp = () => <CalendarComponent />;
const ReviewsComponent = () => <div>Reviews Component</div>;
const ProfileComponent = () => <Profile />;
const AccountSettingsComponent = () => <div>Account Settings Component</div>;
const MembershipComponent = () => <div>Membership Component</div>;

export default function Dashboard() {
    const [activeView, setActiveView] = useState('Dashboard');
    const [openModal, setOpenModal] = useState(false);
    const pb = useContext(PocketBaseContext);
    const user = pb.authStore.model;
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const path = location.pathname;
        const view = path.split('/').pop();
        const decodedView = decodeURIComponent(view); // Decode URL-encoded characters
        console.log(decodedView)
        const capitalizedView = decodedView.charAt(0).toUpperCase() + decodedView.slice(1);
        console.log(`Current active view: ${capitalizedView}`);
        setActiveView(capitalizedView);
    }, [location]);

    const handleViewChange = (view) => {
        const normalizedView = encodeURIComponent(view.trim().toLowerCase());
        navigate(`/dashboard/${normalizedView}`);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    function SidebarItem({ icon, text, onClick, active }) {
        return (
            <div onClick={onClick} className="flex items-center cursor-pointer pt-2">
                <span className="mr-3">{icon}</span>
                <div className='gradient-border-container'>
                    <GradientButton className='border-transparent'>{text}</GradientButton>
                </div>
            </div>
        );
    }

    return (
        <div className="h-screen flex overflow-hidden pt-2">
            <div className="sidebar w-80 bg-white shadow-md flex flex-col p-6">
                <div className="sidebar-menu flex flex-col">
                    <SidebarItem
                        icon={<span><HomeOutlinedIcon /></span>}
                        text="Dashboard"
                        onClick={() => handleViewChange('Dashboard')}
                        active={activeView === 'Dashboard'}
                        className="border-transparent"
                    />
                    <SidebarItem
                        icon={<span><EmailOutlinedIcon /></span>}
                        text="Message"
                        onClick={() => handleViewChange('Message')}
                        active={activeView === 'Message'}
                        className="border-transparent"
                    />
                    <ul className="flex flex-col text-left">
                        <SidebarItem
                            icon={<span><AddHomeWorkOutlinedIcon /></span>}
                            text="My Properties"
                            onClick={() => handleViewChange('My Properties')}
                            active={activeView === 'My Properties'}
                        />
                        <SidebarItem
                            icon={<span><AddHomeOutlinedIcon /></span>}
                            text="Add New Property"
                            onClick={() => handleViewChange('Add a New Property')}
                            active={activeView === 'Add a New Property'}
                        />
                        <SidebarItem
                            icon={<span><GradeOutlinedIcon /></span>}
                            text="Favorites"
                            onClick={() => handleViewChange('Favorites')}
                            active={activeView === 'Favorites'}
                        />
                        <SidebarItem
                            icon={<span><SearchOutlinedIcon /></span>}
                            text="Saved Search"
                            onClick={() => handleViewChange('Saved Search')}
                            active={activeView === 'Saved Search'}
                        />
                        <SidebarItem
                            icon={<span><CalendarMonthOutlinedIcon /></span>}
                            text="Calendar"
                            onClick={() => handleViewChange('Calendar')} // Use "Calendar" instead of "Calendarcomp"
                            active={activeView === 'Calendar'} // Match activeView to "Calendar"
                        />
                        <SidebarItem
                            icon={<span><AddCommentOutlinedIcon /></span>}
                            text="Reviews"
                            onClick={() => handleViewChange('Reviews')}
                            active={activeView === 'Reviews'}
                        />
                    </ul>
                    <ul className="flex flex-col text-left">
                        <SidebarItem
                            icon={<span><AccountCircleOutlinedIcon /></span>}
                            text="Profile"
                            onClick={() => handleViewChange('Profile')}
                            active={activeView === 'Profile'}
                        />
                    </ul>
                </div>
            </div>

            <div className="main flex-1 overflow-y-auto p-5">
                <div className="section-header flex justify-center items-center mb-4">
                    <h2 className="text-lg font-bold">{activeView}</h2>
                    {activeView === 'Add a new property' && (
                        <GradientButton onClick={handleOpenModal}>Add a Property</GradientButton>
                    )}
                </div>


                <div className="tiles-container flex flex-wrap justify-center">
                    {activeView === 'Dashboard' && <DashboardComponent />}
                    {activeView === 'Message' && <MessageComponent user={user} />}
                    {activeView === 'My Properties' && <MyPropertiesComponent />}
                    {activeView === 'Add a New Property' && (
                        <GradientButton onClick={handleOpenModal}>Add a Property</GradientButton>
                    )}
                    {activeView === 'Favorites' && <Favorites />}
                    {activeView === 'Saved Search' && <SavedSearchComponent />}
                    {activeView === 'Calendar' && <CalendarComp />}
                    {activeView === 'Reviews' && <ReviewsComponent />}
                    {activeView === 'Profile' && <ProfileComponent />}
                    {activeView === 'Account Settings' && <AccountSettingsComponent />}
                    {activeView === 'Membership' && <MembershipComponent />}
                </div>
            </div>

            <ProgressModal open={openModal} onClose={handleCloseModal} />
        </div>
    );
}
